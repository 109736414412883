<template>
  <div>
    <p class="text-2xl mb-6">Configuracion</p>

    <v-row class="match-height">
      <v-col cols="12" sm="6" lg="4" v-for="(item, index) in configuraciones" :key="index">
        <v-card>
          <v-card-text>
            <TipoValor
              v-if="item.id_configuracion_tipo_atributo == 1"
              :label="item.nombre"
              v-model="item.valor"
              :descripcion="item.descripcion"
            />
            <TipoSelect
              v-else-if="item.id_configuracion_tipo_atributo == 2"
              :label="item.nombre"
              v-model="item.valor"
              :descripcion="item.descripcion"
              :opciones="item.agrupacion"
            />
            <TipoBoolean
              v-else-if="item.id_configuracion_tipo_atributo == 3"
              :label="item.nombre"
              v-model="item.valor"
              :descripcion="item.descripcion"
            />
            <TipoHora
              v-else-if="item.id_configuracion_tipo_atributo == 6"
              :label="item.nombre"
              v-model="item.valor"
              :descripcion="item.descripcion"
            />
            <TipoFecha
              v-else-if="item.id_configuracion_tipo_atributo == 4"
              :label="item.nombre"
              v-model="item.valor"
              :descripcion="item.descripcion"
            /> 
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn color="primary" @click="actualizar()">Guardar <v-icon>mdi-plus</v-icon> </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
import MaestroService from '@/api/servicios/MaestroService.js'
import TipoBoolean from './componentes/TipoBoolean.vue'
import TipoHora from './componentes/TipoHora.vue'
import TipoFecha from './componentes/TipoFecha.vue'
import TipoSelect from './componentes/TipoSelect.vue'
import TipoValor from './componentes/TipoValor.vue'
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import store from '@/store'
// demos

export default {
  components: {
    TipoBoolean,
    TipoSelect,
    TipoValor,
    TipoHora,
    TipoFecha
  },
  setup() {
    onBeforeMount(() => {
      cargarDatos()
    })
    const configuraciones = ref([])
    const cargarDatos = () => {
      MaestroService.configuracionListar()
        .then(response => {
          configuraciones.value = response.data.datos
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {})
    }

    const actualizar = () => {
      MaestroService.configuracionActualizar({ configuracion: configuraciones.value })
        .then(response => {
          if (response.data.mensaje == 'GUARDADO CON EXITO') {
            cargarDatos() 
            store.commit('setAlert', {
              message: 'GUARDADO CON EXITO',
              type: 'success',
            })
          } else { 
            store.commit('setAlert', {
              message: 'Error '+response.data.mensaje,
              type: 'warning',
            })
          }
        })
        .catch(err => {
          store.commit('setAlert', {
              message: 'Error '+err,
              type: 'error',
              
              error: {
                        ...err, 
                        response: error?.response?.data
                    },
              funcion: 'actualizar-configuracion',
            })
        })
        .finally(() => {})
    }
    return {
      configuraciones,
      cargarDatos,
      actualizar,
      store
    }
  },
}
</script>
