<template>
  <div>
    <h4 for="">{{ label }}</h4> 
    <v-dialog
        ref="dialog"
        v-model="modal2"
        :return-value.sync="valor"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          outlined
          dense
            v-model="valor"  
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-if="modal2"
          v-model="valor"
          full-width 
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="modal2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.dialog.save(valor)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>
    <p class="mt-4" v-if="descripcion">{{ descripcion }}</p>
  </div>
</template>
  <script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
export default {
  props: {
    label: {
      type: String,
      default: 'Tipo valor hora',
    },
    descripcion: {
      type: String,
      default: 'Descripcion',
    },
    value: {
      type: String,
      default: "",
    },
  },
  setup(props, contex) {
    const valor = ref(false)
    onBeforeMount(() => {
      valor.value = props.value
    })

    const returnDato = () => {
      contex.emit('input', valor.value)
    }
    watch(valor, () => {
      returnDato()
    })
const modal2 = ref(false) 
    return  {
      valor,
      modal2
    }
  },
}
</script>